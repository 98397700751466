import { render, staticRenderFns } from "./crudReport.vue?vue&type=template&id=4aa1bfc3&scoped=true&"
import script from "./crudReport.vue?vue&type=script&lang=js&"
export * from "./crudReport.vue?vue&type=script&lang=js&"
import style0 from "./crudReport.vue?vue&type=style&index=0&id=4aa1bfc3&prod&lang=css&"
import style1 from "./crudReport.vue?vue&type=style&index=1&id=4aa1bfc3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4aa1bfc3",
  null
  
)

export default component.exports